import React from 'react'
import TypographyPro from 'themes/TypographyPro'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import ECommerceFooterSectionItem from './ECommerceFooterSectionItem'

import type _Application from 'mobx/Application'
import type { SectionItem } from './ECommerceFooterSectionItem'

const StyledSectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 959px) {
		padding: 5px 40px;
	}
`

const StyledTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 959px) {
		margin: 15px 0;
	}
`

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)<{ $open: boolean }>`
	display: none;

	@media (max-width: 576px) {
		display: block;
		color: var(--optionalBackground);
		transform: rotate(${({ $open }) => ($open ? `180deg` : `0`)});
	}
`

const StyledSectionTitle = styled(TypographyPro)`
	color: var(--optionalBackground);
`

const StyledItemSection = styled.div`
	flex-direction: column;
	display: flex;
`

interface Props {
	Application: typeof _Application
	idx: number
	sectionName: string
	sectionItems: SectionItem[]
	clickHandler: (sectionName: string) => void
	showItems: boolean
	isGeneratedStatically?: boolean
}

const ECommerceFooterSection = (props: Props) => {
	const { Application, sectionName, sectionItems = [], clickHandler, showItems, idx, isGeneratedStatically = false } = props

	return (
		<StyledSectionContainer>
			<StyledTitleContainer onClick={() => clickHandler(sectionName)}>
				<StyledSectionTitle component="div" variant="BodyRegularHighlighted">
					{sectionName}
				</StyledSectionTitle>
				<StyledArrowDropDownIcon $open={showItems} />
			</StyledTitleContainer>
			<StyledItemSection>
				{showItems &&
					sectionItems.map((sectionItem, index) => (
						<ECommerceFooterSectionItem
							sectionItem={sectionItem}
							index={index}
							key={index}
							isGeneratedStatically={isGeneratedStatically}
							page={Application.page}
						/>
					))}
			</StyledItemSection>
		</StyledSectionContainer>
	)
}

export default inject('Application')(observer(ECommerceFooterSection))
