// @ts-nocheck
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { getTranslatedTextByKey, isMobile, isNodeJsEnv } from 'utils/utils'
import { isNextJS } from '../../../utils/nextUtils'
import { isEmpty } from 'lodash-es'
import LanguageSelector from './StoreLocator/LanguageSelector'
import { inject, observer } from 'mobx-react'
import IconComponent from 'themes/IconComponent'
import TypographyPro from 'themes/TypographyPro'
import ECommerceFooterSection from 'static/components/ECommerceFooterSection'
import versionInfo from '../../../version.json'
import { Logo, LOGO_TYPE } from 'components/common/Logo'

// assets
const GoogleStoreIcon = (props) => <IconComponent asset="/icons/googlePlay.svg" {...props} />
const AppleStoreIcon = (props) => <IconComponent asset="/icons/apple.svg" {...props} />
const InstagramCircleIcon = (props) => <IconComponent asset="/icons/instagramCircle.svg" {...props} />
const FacebookCircleIcon = (props) => <IconComponent asset="/icons/facebookCircle.svg" {...props} />
const TwitterCircleIcon = (props) => <IconComponent asset="/icons/xSocialCircle.svg" {...props} />
const YoutubeCircleIcon = (props) => <IconComponent asset="/icons/youtubeCircle.svg" {...props} />
const LinkedinCircleIcon = (props) => <IconComponent asset="/icons/linkedinCircle.svg" {...props} />

const YEAR_TOKEN = '%year%'
const { version } = versionInfo

const StyledFooter = styled.div`
	background-color: var(--footerAndDarkBackgrounds);
	padding-top: 20px;
	width: 100%;
	margin-top: auto;

	@media (max-width: 600px) {
		padding-bottom: ${({ $isCheckoutButtonVisible }) => ($isCheckoutButtonVisible ? '70px' : '0px')};
	}
`

const StyledDivider = styled(Divider)`
	@media (min-width: 960px) {
		display: none;
	}
	background-color: var(--inactive);
	margin: 0 20px;
`

const SocialMediaLink = styled.a`
	margin: 0 5px;

	@media (max-width: 959px) {
		margin: 0 10px;
	}
`

const socialIconStyle = css`
	@media (max-width: 959px) {
		transform: scale(1.5);
	}

	& circle {
		fill: var(--footerAndDarkBackgrouns);
		stroke: var(--inactive);
	}
`

const StyledFacebookIcon = styled(FacebookCircleIcon)`
	${socialIconStyle}
`

const StyledInstagramIcon = styled(InstagramCircleIcon)`
	${socialIconStyle}
`

const StyledTwitterIcon = styled(TwitterCircleIcon)`
	${socialIconStyle}
`

const StyledYoutubeIcon = styled(YoutubeCircleIcon)`
	${socialIconStyle}
`

const StyledLinkedinIcon = styled(LinkedinCircleIcon)`
	${socialIconStyle}
`

const StyledLogoGrid = styled(Grid)`
	text-align: center;
`

const StyledAppGrid = styled(Grid)`
	@media (max-width: 959px) {
		margin: 15px 0 70px 20px;
		flex-direction: row;
	}
	display: flex;
	flex-direction: column;
`

const StyledAppLink = styled.a`
	@media (max-width: 959px) {
		margin-inline-end: 15px;
	}
	margin-bottom: 12px;
`

const LogoContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 13px;
`

const FooterLogo = styled(Logo)`
	height: 100vh;
	width: 100vw;
	max-width: 125px;
	max-height: 43px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;

	@media (max-width: 959px) {
		background-position: center;
	}

	@media (min-width: 960px) {
		margin-bottom: 30px;
	}
`

const StyledCSSGridContainer = styled.div`
	display: grid;
	justify-items: center;
	padding: 20px 0 30px 0;

	@media (max-width: 959px) {
		grid-template-rows: auto;
		${({ $showLanguageSelector }) =>
			$showLanguageSelector
				? css`
						grid-template-areas:
							'languages social'
							'copyright copyright';
				  `
				: css`
						grid-template-areas:
							'social social'
							'copyright copyright';
				  `}
	}

	@media (min-width: 960px) {
		grid-template-columns: 18% 63% 11%;
		grid-template-areas: 'languages copyright social';
		align-items: center;
	}
`

const CSSGridLanguages = styled.div`
	grid-area: languages;
`

const CSSGridCopyright = styled(TypographyPro)`
	grid-area: copyright;
	color: var(--optionalBackground);
	text-align: center;
	padding: 0 20px;

	@media (min-width: 576px) {
		padding: 0 40px;
	}

	@media (max-width: 959px) {
		margin-top: 20px;
		text-align: center;
	}
`

const CSSGridSocial = styled.div`
	grid-area: social;
	white-space: nowrap;
	@media (max-width: 576px) {
		margin-inline-start: ${({ $showLanguageSelector }) => $showLanguageSelector && '50px'};
	}
`

const socialIconMapping = {
	facebook: StyledFacebookIcon,
	instagram: StyledInstagramIcon,
	twitter: StyledTwitterIcon,
	youtube: StyledYoutubeIcon,
	linkedin: StyledLinkedinIcon,
}

const ECommerceFooter = inject(
	'Application',
	'User',
	'AddressManager'
)(
	observer(({ data, logos, languages, Application, User, AddressManager }) => {
		const [openedSections, setOpenedSections] = useState({})
		const [isClickable, setIsClickable] = useState(false)
		const numOfSections = data?.sections.length
		const gridSize = Math.floor(12 / numOfSections)
		const isGeneratedStatically = true
		const checkoutAvailableInPages = ['home', 'account', 'deals']
		const isCheckoutButtonVisible = checkoutAvailableInPages.includes(Application.page) && (AddressManager.isUserLocalized() || User.isChatApp())

		useEffect(() => {
			const sectionNames = data?.sections.map((section) => section.name)

			if (isMobile()) {
				sectionNames.forEach((sectionName) =>
					setOpenedSections((prevState) => ({
						...prevState,
						[sectionName]: false,
					}))
				)
				setIsClickable(true)
			} else {
				sectionNames.forEach((sectionName) =>
					setOpenedSections((prevState) => ({
						...prevState,
						[sectionName]: true,
					}))
				)
				setIsClickable(false)
			}
		}, [])

		if (Application.isMobileApp) {
			return null
		}

		const clickHandler = (sectionName) => {
			setOpenedSections((prevState) => ({ ...prevState, [sectionName]: !prevState[sectionName] }))
		}

		const renderSocialMedia = (socialMedia) => {
			const socialMediaLinks = []

			for (const socialName of Object.keys(socialMedia)) {
				const { link } = socialMedia[socialName]
				const SocialIcon = socialIconMapping[socialName]

				if (link && SocialIcon) {
					socialMediaLinks.push(
						<SocialMediaLink href={link} target={socialName} key={socialName} data-testid={`social-${socialName}-link`}>
							<SocialIcon />
						</SocialMediaLink>
					)
				} else {
					console.error(`WARNING: ${socialName} is not existing socialName !!!`)
				}
			}

			return <CSSGridSocial>{socialMediaLinks}</CSSGridSocial>
		}

		const copyrightText = data?.copyrightText || ''
		const formattedCopyrightText = copyrightText.replace(YEAR_TOKEN, new Date().getFullYear())

		const content = (
			<StyledFooter id="footer" data-testid="footer-section" $isCheckoutButtonVisible={isCheckoutButtonVisible}>
				{/* Top Row */}
				<Grid container>
					<StyledLogoGrid item xs={12} sm={12} md={2} lg={2} xl={2}>
						<LogoContainer>
							<FooterLogo logoType={LOGO_TYPE.FOOTER} />
						</LogoContainer>
					</StyledLogoGrid>

					<Grid container item xs={12} sm={12} md={8} lg={8} xl={8} justifyContent="center">
						{data?.sections.map((section, idx) => (
							<Grid item key={section.sectionId} xs={12} sm={12} md={gridSize} lg={gridSize}>
								<StyledDivider />
								<ECommerceFooterSection
									clickHandler={isClickable ? clickHandler : () => {}}
									sectionName={section.name}
									sectionItems={section.items}
									showItems={
										openedSections[section.name] || (isNodeJsEnv && !isNextJS())
										/* Always include all footer links while server side renderig
                                        except for nextJS  */
									}
									isGeneratedStatically={isGeneratedStatically}
									idx={idx}
								/>
								{data.sections.length - 1 === idx && <StyledDivider />}
							</Grid>
						))}
					</Grid>

					{!isEmpty(data?.apps) && (
						<StyledAppGrid item container xs={12} sm={12} md={2} lg={2} xl={2}>
							{data?.apps?.ios?.link && (
								<StyledAppLink href={data?.apps.ios.link} target="ios" data-testid="appstore-link">
									<AppleStoreIcon />
								</StyledAppLink>
							)}
							{data?.apps?.android?.link && (
								<StyledAppLink href={data?.apps.android.link} target="android" data-testid="playstore-link">
									<GoogleStoreIcon />
								</StyledAppLink>
							)}
						</StyledAppGrid>
					)}
				</Grid>

				{/* last row */}
				<StyledCSSGridContainer $showLanguageSelector={languages && languages.length > 1}>
					{languages && languages.length > 1 && (
						<CSSGridLanguages>
							<LanguageSelector isGeneratedStatically={isGeneratedStatically} languages={languages} fontColor="#ffffff" />
						</CSSGridLanguages>
					)}

					<CSSGridCopyright component="div" variant="BodySmall" data-testid="copyright-text">
						{formattedCopyrightText}
						<div>
							{getTranslatedTextByKey('webviewFlow.version', 'version')}&nbsp;{version}
						</div>
					</CSSGridCopyright>
					{renderSocialMedia(data?.socialMedia ?? {})}
				</StyledCSSGridContainer>
			</StyledFooter>
		)

		return content
	})
)

export default ECommerceFooter
