import React from 'react'
import Link from 'next/link'
import TypographyPro from 'themes/TypographyPro'
import styled from 'styled-components'
import { kebabCase } from 'lodash-es'

import SmartLink from './SmartLink'
import Picture from './Picture'

import type { Page } from 'utils/constants'

/*
 * This is to style the OneTrust cookie consent button, !important has to be used to override OneTrust's styling which
 * has higher specificity.
 */
const StyledCookieConsentLink = styled(TypographyPro)`
	border: initial !important;
	padding: 0 0 15px !important;
	text-decoration: none;
	color: var(--optionalBackground) !important;
	background-color: transparent !important;
`

export interface SectionItem {
	itemType: string
	itemId: string
	text: string
	path: string
	image?: string
}

interface SectionItemProps {
	sectionItem: SectionItem
	index: number
	isGeneratedStatically: boolean
	page: Page
}

const ECommerceFooterSectionItem = ({ sectionItem, index, isGeneratedStatically, page }: SectionItemProps) => {
	// Currently (19.09.24), image field, can be added only through the database (manually). Developed for KFC Peru Legal Notice
	if (sectionItem.image) {
		return (
			<Link href={sectionItem.path} key={index}>
				<Picture src={sectionItem.image} alt={sectionItem.text} />
			</Link>
		)
	}

	if (sectionItem.itemType === 'cookieConsent') {
		return (
			<StyledCookieConsentLink key={sectionItem.itemId} id="ot-sdk-btn" className="ot-sdk-show-settings" variant="BodySmall" component="a">
				{sectionItem.text}
			</StyledCookieConsentLink>
		)
	}

	return (
		<SmartLink
			openInSameBrowserTab={sectionItem.itemType !== 'external'}
			sectionItem={sectionItem}
			isGeneratedStatically={isGeneratedStatically}
			key={sectionItem.itemId}
			style={{
				textDecoration: 'none',
				paddingBottom: '15px',
				color: 'var(--optionalBackground)',
			}}
			testId={`footer-link-${index}`}
			id={`${page}-page-end-bar-${kebabCase(sectionItem.itemId)}`}
		>
			{sectionItem.text}
		</SmartLink>
	)
}

export default ECommerceFooterSectionItem
