import React from 'react'

interface PictureProps {
	src: string
	alt: string
	sources?: {
		srcSet: string // image URL
		media?: string // media query like "(min-width: 650px)"
		type?: 'image/jpeg' | 'image/png' | 'mage/webp' | 'image/gif' | 'image/svg+xml' | 'image/avif'
	}[]
}

const Picture = ({ src, alt, sources }: PictureProps) => (
	<picture>
		{sources && sources.length && sources.map(({ media, srcSet, type }) => <source media={media} srcSet={srcSet} type={type} />)}
		<img src={src} alt={alt} />
	</picture>
)

export default Picture
